<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('sector.add.title') }}
             </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>

            <template v-slot:extension>
              <v-tabs v-model="tabs" fixed-tabs>
                <v-tabs-slider></v-tabs-slider>
                <template v-if="page">
                  <v-tab
                    v-for="(language, index) in page.web_languages"
                    :href="'#' + index"
                    class="primary--text"
                    :key="'tab_' + index"
                  >
                    {{ language.label }}
                  </v-tab>
                </template>
              </v-tabs>
            </template>
          </v-toolbar>

          <v-divider></v-divider>
          <template v-if="page">
            <v-tabs-items v-model="tabs">
              <v-tab-item
                v-for="(language, index) in page.web_languages"
                :key="'tab_content_' + index"
                :value="index"
              >
                <translations
                  :language="index"
                  v-on:translation-updated="updateTranslation"
                ></translations>
              </v-tab-item>
            </v-tabs-items>
          </template>
          <v-file-input
            :label="$t('sector.add.sectorImage')"
            filled
            prepend-icon="mdi-camera"
            v-model="sectorData.image"
            id="sectorImage"
            @change="onFileChange"
          ></v-file-input>

          <v-card v-if="preview">
            <v-img
              :src="preview"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="$t('sector.add.currentImage')"></v-card-title>
            </v-img>
          </v-card>

          <v-text-field
            v-model="sectorData.internalName"
            :counter="15"
            :label="$t('sector.add.internalName')"
            required
          ></v-text-field>

          <v-btn
            color="success"
            class="mr-4 align-self-lg-end"
            @click="createSector()"
            >
            {{ $t('sector.add.controlls.create') }}
            </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
import Translations from "../../components/translation/sector/Translations.vue";

export default {
  components: { Translations },
  name: "CreateSector",
  data() {
    return {
      error: null,
      sectorData: {
        translations: {},
        image: null,
        internalName: null,
      },
      preview: null,
      tabs: null,
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    page() {
      if(this.applicationData) {
        return this.applicationData; 
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch("loadApplicationData")
  },
  methods: {
    createSector() {
      var formData = new FormData();

      var imagefile = document.querySelector("#sectorImage");

      formData.append("image", imagefile.files[0]);
      formData.append("internalName", this.sectorData.internalName);
      for (const language in this.sectorData.translations) {
        for (const languageValues in this.sectorData.translations[language]) {
          formData.append(
            "translations[" + language + "][" + languageValues + "]",
            this.sectorData.translations[language][languageValues]
          );
        }
      }

      this.$axios({
        method: "post",
        url: this.$globals.api.sector.create,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.$router.push({ name: "sector/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },

    onFileChange() {
      if (this.sectorData.image != null) {
        this.preview = URL.createObjectURL(this.sectorData.image);
      } else {
        this.preview = null;
      }
    },

    updateTranslation(translationData) {
      this.sectorData.translations[translationData.language] =
        translationData.data;
    },
  },
};
</script>